import React, {useState, useRef, useEffect} from 'react';


import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import "./../components-css/media-preview-grid.scss"


const MediaPreviewGrid = ({items, thumbnailRenderer}) =>{


	// console.log ('media-preview-grid items=',items)
	const RenderPreview = (item, key)=> {

		return (
			<div 
				// xs={3} 
				className="media-item-wrapper"
				key={key}
			>
				<div className="media-item">
					{thumbnailRenderer(item)}
				</div>
			</div>
		);
	}


	return (
		<div className={"media-grid-wrapper " + ( items.length > 4 ? "scroll-shadow" : '')}>
			<div className="media-preview-grid ">
				{items.map((item, i)=> RenderPreview(item, item.key) )}
			</div>
		</div>
	);
}

export {MediaPreviewGrid}