import React, {useState, useEffect, useRef, createContext, useContext} from 'react';
// import Home from './page/Home';
// import Signup from './page/Signup';
// import Login from './page/Login';
// import {SignIn} from './components/sign-in.component'



import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import {Animation} from '../components/animation.component'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./../components-css/animation-demo.scss"

// https://andrewraycode.github.io/easing-utils/gh-pages/
// import 'easing-utils/dist/easing.js'


// / Curly braces needed for import
// import {linear, easeOutBack,easeOutSine,easeInElastic, easeOutElastic,easeInQuart,easeOutQuart,easeInSine, easeInCubic, easeInOutBounce } from 'easing-utils';

// Pass in a value from 0 - 1 and get the eased value back








// const AnimationContext = createContext({a:344, b:()=>alert('beeps')});







// const AdvAnimation = ({
// 	imagesPath, 
// 	numImages, 
// 	canvasWidth, 
// 	canvasHeight, 
// 	isPlaying, 
// 	frameRate=30, 
// 	onAnimationEnd })=>{


// 	const genFilenames=(path, count)=> {
// 		let names = []
// 		for (var i=1;i<count;i+=skipFrames) // 65
// 		{
// 			let nr = i+''
// 			while (nr.length < 6){ nr = '0'+nr}
// 			// let filename = 'http://localhost:3000/static/animation2/frame-'+nr+'.png'

// 			let filename = path+nr+'.png';	
// 			names.push(filename)	
// 		}
// 		return names;
// 	}	

// 	useEffect(()=>{

// 		// let files = getn


// 	}, [imagesPath, numImages])

// }


export default function AnimationDemo() {

	var video = useRef(null);
	
	var [readyToPlay,setReadyToPlay] = useState(false);
	var [isPlaying, setIsPlaying] = useState(false);

	

	




	return (

			<Container className="animation-demo">
				<Row>
				<Col>
				<h1>contex</h1>
				{/*<Dummy/>*/}
				{/*<h1>Pineappel will have the best reaction animations</h1>*/}
				</Col>
				</Row>
				<Row>
					<Col>
						<div className="video-frame">
							
							{/*<img className="bg" src="http://localhost:5556/get_content/zCq03T8uXGGVIDfjy4fg/0bc69879eba75154708680af7c40a0113bafb0bd/thumbnail?session_id=c8805e7598dd9edc53a8f2518a6d4940d5d62526"/>*/}
							{/*<video 
							ref={video}
							controls src="http://localhost:5556/get_content/9Wp4o9gh2gbOtI4RfRZg/414f4331ebc5a38615b6d220e2e74f6ca98e3180/preview?session_id=59c3c0c1ec0d63c6f968c5debe00fe55bc290b87"
							/>*/}
							

							<Animation
								// frames={images}
								path="http://localhost:3000/static/animation3/frame-"
								imagesCount={78}
								isPlaying={isPlaying}
								// frameRate={33.33 * skipFrames}
								onAnimationEnd={  ()=>{
									setIsPlaying(false)
									return false;
								}}

							/>




						</div>
						
						<button onClick={()=>{
							setIsPlaying(true)
						}}>play animation</button>
					</Col>
					
				</Row>
			
			
			</Container>

		)
}