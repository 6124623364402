import React, {useState, useEffect, useRef} from 'react';
// import Col from 'react-bootstrap/Col';
// import Form from 'react-bootstrap/Form';
// import Row from 'react-bootstrap/Row';
// import Button from 'react-bootstrap/Button';
// import Container  from 'react-bootstrap/Container';
import User from '../utils/userdata.app.js'


// import AnimatedIcon from './animated-icon.component.jsx'
import {ReactComponent as CloseChatIcon} from './../assets/close.svg'
import {ReactComponent as ChatLikeIcon} from './../assets/chat-like.svg'
import {ReactComponent as ChatLikeAltIcon} from './../assets/chat-like-alt.svg'
import {ReactComponent as SubmitChatIcon} from './../assets/submit-chat.svg'
import {AvatarComponent} from './avatar.component'
import {VerifiedBadge} from '../components/verified-badge.component';

import {timeSince} from '../utils/time-since.js';
import {Spinner} from "../components/spinner.component";

import "./../components-css/chat.scss"

const ChatComponent = ({post, chatOpen = false, onPreviewClick,onSubmit, onClose})=>
{
	// console.log(post)
	// r.log(chatOpen)
	var user = new User()

	// because managing deep state is difficult and other posts should not 
	// care about reply forms, we manage it in a separate state..
	var [replyToMessage, setReplyToMessage] = useState(null); // keeps track on which message to reply to
	var [isSubmitting, setIsSubmitting] = useState(false); // keeps track on whether api request is ongoing and we should show a spinner
	var [scrollTarget, setScrollTarget] = useState(null); //keep track to which emelemt to scroll 
	// var [isChatClosing, setChatClosing] = useState(false); // controls if any input needs to be focused
	


	const onSubmitWrapper = (msg, replyToMessage)=>
	{
		setIsSubmitting(true)
		return onSubmit(msg, replyToMessage).then(({post, msg_key})=>{
			setIsSubmitting(false);
			setScrollTarget(msg_key)
		})

	}

	const RepliesComponent = ({replies})=>{


		if (replies.length===0) return null

		return (
			<div className="replies">
				{replies.map((r,i)=>{
					return (
						<ChatMessageComponent 
							msg={r} 
							id={r.key}
							key={r.key}
							showReplyForm={false}
							showReplyOption={false}
							showLikeOption={false}
							showBadge={false}
						/>)
				})}
			</div>
		);
		
	}

	



	
	
	/*
		Sub-component to render individual chat messages
	*/
	const ChatMessageComponent = ({msg, id, postKey = null, showBadge=true, showReplies=true, showReplyOption = false, showFakeReplyLink=false, showReplyForm=false, showLikeOption=true}) =>{
		

		msg.replies = msg.replies || [];
		let [isLiked, setIsLiked] = useState(false)

		const onMessageLike = (msg, post_key)=>{

			console.log(msg, post_key);
			setIsLiked(!isLiked);
		}
		

		let user = new User()

		// var isLiked = msg.liked_by.indexOf( user.getProperty('user_key'))

		return (
				<>
					<div 
						className="history-item"
						id={id}
					>

						{/*<div className="avatar">
							<img src={msg.sender_avatar} alt={msg.sender_name}/>
						</div>
*/}
						<AvatarComponent
							image={msg.sender_avatar} 
							name={msg.sender_name}
							showBadge={showBadge}
						/>
						<div className="message">
							<div className="sender">{msg.sender_name}<VerifiedBadge/></div>
							<div className="body">{msg.message}</div>
							<div className="details">
								<div className="date">{timeSince(msg.created_at)} ago</div>

								{showReplyOption && !showReplyForm?
									<div className="reply"> 
										<a 
											href='#' 
											onClick={(e)=>{
													setReplyToMessage(msg.key);
													setScrollTarget('form_'+msg.key);
												}
											}>Reply</a></div>
									: null }


							</div>


							<div className="react">
							{showLikeOption?
								<>
									<button 
										onClick={(event)=>onMessageLike(msg, postKey)/* (e)=>onSubmitWrapper('❤️', msg.key)*/}
									>
										{isLiked ? <ChatLikeAltIcon/> :  <ChatLikeIcon/>}
									</button>
									<span className="number">{0}</span>
								</>
							:null}
								
							</div>

							
							{showReplies ? <RepliesComponent replies={msg.replies} /> : null}
							
							
							
							
							
							<div className="reply-form">
								{showReplyForm ? (
								<>

									<SendMessageForm 
										id={"form_"+msg.key}
										replyToMessage={msg.key} 
										showEmojiShortcuts={false} 
										showAvatar={false}
										placeholder="Add reply.."
									/>
									<button onClick={()=>setReplyToMessage(null)}>
										<CloseChatIcon/>
									</button>
								</>
									) : null}
							</div>
						</div>
						

					</div>
					
				</>



				)


	}

	const SendMessageForm = ({parent, replyToMessage, placeholder, showAvatar=true, showEmojiShortcuts=true, id}) =>{
		var messageInput = useRef(null)
		var [showSubmitButt, setShowSubmitButt] = useState(false);
		// var [showLoader, setShowLoader] = useState(false)

		
	
		const sendMessage = (event)=>{
			// console.log('sending message')

			
			let message = messageInput.current.value;
			
			if ((message.length > 0) && !isSubmitting)
			{
				onSubmitWrapper(message, replyToMessage)
				setReplyToMessage(null)
				messageInput.current.value = ""
				event.preventDefault()
				return false	
			}
			
		}

		const onChange = (event)=>{
			setShowSubmitButt(event.target.value.length>0);
		}

		const onEmojiBarClick = (event, emoji)=>
		{
			// onSubmitWrapper(emoji);e.preventDefault();return false;

			let input =  messageInput.current;
			let startPos = input.selectionStart;


			input.value = input.value.slice(0, startPos) + emoji + input.value.slice(startPos)
			setShowSubmitButt(true);
		}



		return (
			<>
				

				<div className={"input-form "+(!showAvatar ? 'no-padding':'')}>
					{showEmojiShortcuts ? (
					<div className="emoji-wrapper">
							<ul className="emoji-list">
							{['❤️','😀','🥰','❤️','😀','🥰','🥳','😭'].map((emoji,i)=>
								<li key={i}>

									<button  onClick={(e)=>onEmojiBarClick(e, emoji)}>{emoji}</button>
								</li>
							)}
							</ul>
					</div>) : null}
					<form onSubmit={sendMessage}  id={id}>
							

							<div className="input-field">
								{showAvatar ? (
								
								<AvatarComponent
									image={user.getProperty('picture','')}
								/>
								// <div className="avatar">
								// 	<img  alt="" src={user.getProperty('picture','')} />
								// </div>
								

								) : null}
								<input 
									className="msg-input"
									type="text" 
									name="message" 
									ref={messageInput}
									placeholder={placeholder}
									autoComplete="off" 
									onChange={onChange}

								/>

								{showSubmitButt? (
								<button 
									className="submit-button" 
									onClick={sendMessage} 
									type="button"
								>


									<SubmitChatIcon/>

								
									{/*<i className={"fi "+ (isSubmitting ? "fi-br-spinner spinner" : "fi-bs-paper-plane") }></i>*/}

									
								</button>): (isSubmitting? <Spinner size="small" /> : '' )}
							</div>
					
					</form>
				</div>
			</>
		)


	}

	const SmoothScroll=(duration, frameCallback)=>
	{
		var t0 = performance.now()
		const onFrame=(time)=>{
			// do what needs to be done on each frame
			let deltaT = performance.now() - t0
			let tFrac = deltaT / duration

			// console.log('anim ', tFrac);
			frameCallback(tFrac);
			if (deltaT < duration)
			{
				requestAnimationFrame(onFrame);	
			}
			
		}
		requestAnimationFrame(onFrame);
	}


	const getRealOffsetHeight = (el, parent) =>{
		
		console.log('getRealOffsetHeight',el, parent)
		var result = 0;
		do{

			
			result += el.offsetTop + el.clientTop;
			// console.log(el, el.offsetTop, result);
		    el = el.offsetParent;
		    
		}
		while((el !== parent) && (el !== null));

		if (el=== null)
		{
			console.warn('something went wrong', el, parent);
		}

		// console.log('getRealOffsetHeight:result, ', result);
		return result
	}


	const scrollToItem = (item_id, scrollable)=>
	{
		//
		// if chat scrolling breaks,
		// check if .chat-history {postition:relative} is set in chat.css
		//
		var item = document.getElementById(item_id);

		if (!item) return;

		// item.style = "border:2px solid blue;"
		// scrollable.style = "border:2px solid blue;"
		var scrollStart = scrollable.scrollTop;

		// console.log('misc= ', item.offsetHeight, scrollable.offsetHeight)
		var scrollEnd = getRealOffsetHeight(item, scrollable) 
					+ item.offsetHeight
					- scrollable.offsetHeight



		// + scrollable.offsetHeight 
		var scrollDist = scrollEnd - scrollStart;
		// console.log('scrolling to', item );
		// console.log('scrollable.scrollEnd', scrollEnd)
		scrollable.scrollTop = scrollEnd;

		SmoothScroll(200, (f) => {scrollable.scrollTop = scrollStart  + scrollDist * f   })


	}


	useEffect(()=>{
		
		if ((scrollTarget !== null) && (chatOpen))
		{
			// console.log('scroll target is set...', scrollTarget);
			scrollToItem(scrollTarget, document.getElementById('chat_history_'+post.key));
		}



	}, [scrollTarget, chatOpen])

	useEffect(()=>{

		// console.log('this!!');
		if (chatOpen!==true) return;
		
		var formId = 'form_primary_'+post.key;
		if (replyToMessage!== null)
		{
			formId = 'form_'+replyToMessage
		}

		// console.log('focusing', chatOpen, formId, scrollTarget)


		// wait until animation stops
		// also only one chat should be open
		// otherwise each chat attempts to focus their inputs
		

		if (document.body.offsetWidth > 500)
		{
			// this should not run on mobile with slider
			// enabled
			setTimeout(()=>
				document
					.getElementById(formId)
					.getElementsByClassName('msg-input')[0]
					.focus()
				, 500);	
		}
		



	}, [chatOpen, replyToMessage])




	const ChatPreview = ({chatItem, chatOpen})=>{
		return (
			<div 
				className={"chat-preview"+(!!chatOpen ? " hidden " : "")} 
				onClick={(e)=>{
						// console.log('chat preview click');
						onPreviewClick(e,post);
						e.stopPropagation();
						e.preventDefault()
						return false;
					}
				} 
			>
					<ChatMessageComponent 
						key={chatItem.key} 
						id=""
						msg={chatItem}
						showReplyForm={false}
						showReplyOption={false}
						showLikeOption={false}
						showReplies={false}
						showBadge={true}
					/>


			</div> 
		);


	}

		

	
	return (
		<>

			{post.chat.length > 0 
				? <ChatPreview 
					chatItem={post.chat[post.chat.length-1]} 
					chatOpen={chatOpen}
				/> 
				: null }

			<div 
				className="chat-wrapper"
			>
				<div 
					className={"chat-container "+(!!chatOpen ? " open " : "closed")}
				>
					<div className="header">
						<h3>{post.chat.length > 0 ? post.chat.length+ ' comments' : 'No comments' }</h3>
						<button 
							className="close"
							onClick={onClose}
						>
							<CloseChatIcon/>
						</button>
					</div>
					<div 
						className="chat-history" 
						id={"chat_history_"+post.key}
						// onScroll={(e)=>console.log(e.target.scrollTop)}
					>
						<div

						>
							{!chatOpen ? null : post.chat.map((message, i)=> (
								<ChatMessageComponent 
									key={message.key} 
									id={message.key}
									msg={message}
									postKey={post.key}
									showReplyForm={replyToMessage === message.key}
									showReplyOption={true}
									showLikeOption={true}
									showBadge={true}
								/>
							))}
						</div>

					</div>
					
						<SendMessageForm 
							showAvatar={true}
							id={"form_primary_"+post.key}
							parent={null}
							showEmojiShortcuts={true}
							replyToMessage={null}
							placeholder="Add comment..."
					
						/>
					
				</div>
			</div>
		</>
		)
}

export {ChatComponent}