import React, {useState, useEffect, useCallback, memo, useRef} from 'react';

import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import "./../fonts/style.css"


import {Input} from '../components/form.input.component.jsx'


const StyleGuide= ({})=>
{
	var cnt = useRef(null);

	var myArr = []

	for (let i=0;i<100;i++)
	{
		myArr.push(i);
	}

	const getRealOffsetHeight = (el, parent) =>{
		
		// console.log('getRealOffsetHeight',el, parent)
		var result = 0;
		do{

			
			result += el.offsetTop + el.clientTop;
			console.log(el, el.offsetTop, result);
		    el = el.parentNode;
		    
		}
		while((el.parentElement !== parent) && (el.parentElement !== null));

		if (el.parentElement=== null)
		{
			console.warn('something went wrong', el, parent);
		}

		// console.log('getRealOffsetHeight:result, ', result);
		return result
	}



	const onClick = (e)=>{

		var el =e.target

		var c = document.getElementById('container');
		console.log(el.offsetTop, el, c)

		var r= getRealOffsetHeight(el, c)
		cnt.current.scrollTop = r
	}
	return (
		<Container>
			<Row>
				<Col>
					<h1>Header Large</h1>
					<h2>Header Medium</h2>
					<h3>Header small</h3>
					<span>text</span>
					<span className="text-strong">text</span>
					<span className="text-medium">text</span>
					<span className="text-small">text</span>
					<button className="button">Button text</button>
					<button className="button button-primary">Button text</button>
					<button className="button roundButt active">X</button>
				</Col>
			</Row>
			<Row>
				<Col>


{/*
				{[
.icon-delete:before {
  content: "\e900";
}
.icon-download_for_offline:before {
  content: "\e901";
}
.icon-favorite:before {
  content: "\e902";
}
.icon-Frame-1263:before {
  content: "\e903";
}
.icon-Group:before {
  content: "\e904";
}
.icon-library_add:before {
  content: "\e905";
}
.icon-maps_ugc:before {
  content: "\e906";
}
] }*/}



				<i className="icon-1" style={{
					// color:'red',
					fontSize:'100px'

				}}/>
				</Col>
			</Row>
			<Row>
				<Col md={4} style={{border:'1px solid red'}}>
					<Input 
						subText="Kanye ir liels"
						inputProps={{placeholder:"Placeme!", type:'text'}}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={4} style={{border:'1px solid red'}}>
					<Input 
						isError={true}
						subText="Kanye ir liels"
						validationRegEx={/^[\w]{4}$/}
					 />
				</Col>
			</Row>
			<Row>
				<Col md={4} style={{border:'1px solid red'}}>
					<Input 
						subText="Validē mani"
						validationErrorMsg="Jābūt 4-5 simboliem"
						inputProps={{
							placeholder:"Placeme!", 
							type:'text',
							pattern:"^[\\w]{3,6}$",
							required:true,

						}}
					 />
				</Col>
			</Row>
			<Row>
				<Col md={4} style={{border:'1px solid red'}}>
					<Input 
						// subText="Validē mani"
						inputProps={{
							placeholder:"Search", 
							type:'search',
							pattern:false,

						}}
					 />
				</Col>
			</Row>
		
		</Container>
		)

}


export default StyleGuide