
import React, {useState, useEffect} from 'react';
import {Routes, Route, Navigate, BrowserRouter as Router, redirect} from 'react-router-dom';

import {SignInWithGoogle} from '../components/sign-in.component'
// import {ContentFeed} from '../components/content-feed.component'

// import Container  from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import Navbar from 'react-bootstrap/Navbar';
// import Nav from 'react-bootstrap/Nav';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
// import 'bootstrap/dist/css/bootstrap.min.css';

import User from '../utils/userdata.app.js'
import {ReactComponent as GridIcon} from './../assets/grid.svg'
import {ReactComponent as PineappelIcon} from './../assets/logo.svg'
import {ReactComponent as UserMenuIcon} from './../assets/user-menu.svg'
import "./../components-css/top-nav.scss"





function TopNav({isLoggedIn, user, navHidden = false, homeUrl='#', gridUrl='#' })
{

	var [isLoggedIn,setLoggedIn] = useState(user && user.hasSessionData());

	function onLogoutClick()
	{
		user.logout()
		setLoggedIn(false)
	}

	function LogoutRedirect(props)
	{
		if (isLoggedIn)
		{
		  return <span>Logout</span>
		}
		else
		{
		  return <Navigate replace to="/" />
		}
	}


	// disables the menu, if user is not passed via props
	const onUserMenuClick = (event)=>
	{
		if (!!user)
		{
			event.preventDefault(); return false;
		}
	}


	return (
		<div className="top-navigation menu-group">
			<a 
				href={homeUrl} 
				className="menu-item pineappel-icon"
			>
	 			<PineappelIcon />
	 		</a>

	 		<div 
	 			className="menu-group"
	 			hidden={navHidden}
	 		>
		 		<a 

		 			href={gridUrl} 
		 			className={"menu-item" + (document.location.pathname === gridUrl ? ' active' : '')}
		 		>
					<GridIcon />

				</a>
				<a 
					href={homeUrl} 
					className={"menu-item" + (document.location.pathname === homeUrl ? ' active': '')}
				>
					Latest
				</a>
			</div>
			<div>
				{/*<a 
					href="#"
					className={"user-menu-toggle menu-item "+(!user ? 'inactive': '')}
					onClick={()=>setUserMenuVisible(!userMenuVisible)}
				>
					
				</a>*/}

				<Dropdown 
					align="end"
					className={"user-menu" }
				>
					<Dropdown.Toggle className="menu-item user-menu-toggle" disabled={!user}>
	       				{!user ? 
							<UserMenuIcon/> 
						: (
							<>
								<img
									src={user && user.getProperty('picture')}
						  			alt={user && user.getProperty('name')}
								/>
								
			  				</>


						)}
	      			</Dropdown.Toggle>
			
				 	<Dropdown.Menu>
				 		<div className="user-profile">
				 			<img
								src={user && user.getProperty('picture')}
					  	  		className="user-picture"
					  			alt={user && user.getProperty('name')}
							/>
				 			<h3>{user && user.getProperty('name')}</h3>
				 			<button className="full-width button button-primary">Get unlimited access</button>
				 		</div>
			        <Dropdown.Divider />
			        <Dropdown.Item href="#">Settings and privacy</Dropdown.Item>
			        <Dropdown.Item href="#" onClick={onLogoutClick}>
						<LogoutRedirect isLoggedIn={isLoggedIn}/>
					</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>




		</div>
		);
	



	
}

export   {TopNav}