
import React, {useState, useEffect, useRef} from 'react';
import {ReactComponent as PremiumBadgeIcon} from './../assets/premium-badge.svg';
import User from '../utils/userdata.app.js'
import '../components-css/verified-badge.scss';

// mob 
// desktop 

export const VerifiedBadge = ({user})=>
{
	let u = !user ? new User() : user;
	
	if (u.isVerified())
	{
		return (
			<PremiumBadgeIcon className="verified-badge"/>
		);
	}
	else
	{
		return null;
	}

}