import {ReactComponent as PineappelIcon} from './../assets/logo.svg'
import "./../components-css/text-content-page.scss"

export const TextContentPage = ({menuItems=[], content})=>{


	return (
		<>
			<div className="text-content-page">
				<div className="menu">
					<a 
						className="home-logo"
						href="/"
					>
						<PineappelIcon/>
						
						<span>Pineappel</span>
					</a>
					<div className="collapse-items">
						{menuItems.map((item,i)=>{
							return (
								<a 
									key={i}
									href={item[1]}
									className="menu-item"
								>
									{item[0]}
								</a>

							);

						})}
					</div>
					

				</div>

				<section className="main-content">
					{content}
				</section>
			</div>
		</>
	);



}
