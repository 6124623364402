

// const baseurl = "http://127.0.0.1:5556"

// require('dotenv').config()



var baseurl = null;
if (process.env.NODE_ENV === 'development')
{
	baseurl = '//'+window.location.hostname+":5556"
	console.info("You are in development now!")
}
else
{
	baseurl = '//'+window.location.hostname+":5556"
	console.info("You are in prod now!", baseurl)

}

const config = {

	// URL to send auth token from google authentication
	verifyGoogleTokenServiceUrl : baseurl+'/verify_google_token_service',
	validateSessionServiceUrl 	: baseurl+'/validate_session',
	logoutServiceUrl 			: baseurl+'/logout',
	contentUploadServiceUrl 	: baseurl+'/upload_content_alt',
	contentFeedServiceUrl 		: baseurl+'/get_content_feed',
	contentServiceUrl 			: baseurl+'/get_content',
	chatSubmitServiceUrl 		: baseurl+'/chat',
	userServiceUrl 				: baseurl+'/update_user',
	downloadServiceUrl 			: baseurl+'/download',
	createCollabServiceUrl 		: baseurl+'/create_collab',
	reactionServiceUrl 			: baseurl+'/reaction',
	respondToInviteServiceUrl 	: baseurl+'/respond_to_invite',
	inviteServiceUrl 			: baseurl+'/invite',
	userSearchService 			: baseurl+'/user_search',
	DMFeedServiceUrl 			: baseurl+'/dm',
	deleteSlideServiceUrl 		: baseurl+'/delete_slide',
	deletePostServiceUrl 		: baseurl+'/delete_collab',
	shareToServiceUrl 			: baseurl+'/shareto',
	newDMsService				: baseurl+'/dm/new',


	// this comes as is from firebase setup
	firebaseConfig : {
		apiKey					: "AIzaSyCI5JaHfzHEG1mmpmmmcLkqUiN9bmAuruI",
		authDomain				: "pinappeldev.firebaseapp.com",
		projectId				: "pinappeldev",
		storageBucket			: "pinappeldev.appspot.com",
		messagingSenderId		: "559096317468",
		appId					: "1:559096317468:web:9c0dd31b70b6c56e5881d9",
		measurementId			: "G-MB2X8PXEZK"
	},


	// Warning: we need to very very very carefully think of allowed chars in 
	// nicknames. Otherwise @rich-textarea components breaks. 
	// 
	nicknamePattern 			: "^[\\w\\d]{3,8}$",
  	MENTION_REGEXP 				: /\B@([\-+\w]*)$/,

  	nicknameReplacePattern 	 	: /[^a-zA-Z0-9]/g


}


export default config