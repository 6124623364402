// this class manages persistent handling of user data
// currently a wrapper around localStorate
import config from './config.app.js';

class User
{
	constructor(data)
	{
		for (let key in data)
		{
			this.setProperty(key, data[key])
		}

		// console.log('New user initialized: ', data)
	}

	isVerified()
	{
		// [todo] read this from the api
		return true;
	}

	getProperty(name, defaultValue)
	{
		return localStorage.getItem(name) || defaultValue
	}

	setProperty(name, value)
	{
		localStorage.setItem(name, value);
	}

	getSessionID()
	{
		return localStorage.getItem('session')
	}


	
	// 
	// More specific methods
	// 

	// Returns true if a valid user session is present
	hasSessionData()
	{
		// check if session exists locally
		return !!this.getProperty('session', false)
		
	}

	wipeLocalSession()
	{
		localStorage.removeItem("session");
		localStorage.removeItem("name");
		localStorage.removeItem("email");
		localStorage.removeItem("picture");
		localStorage.removeItem("success");
		localStorage.removeItem("first_login");
		localStorage.removeItem("user_key");
		localStorage.removeItem("last_seen");
	}

	logout()
	{
		fetch(config.logoutServiceUrl, {
		  method: 'POST',
		  mode: "cors", // no-cors, *cors, same-origin
	      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
	      redirect: "follow",
		  headers: {
		    'Accept': 'application/json',
		    'Content-Type': 'application/json',
		  },
		  body: JSON.stringify({
		    session_id: this.getSessionID()
		  })
		})

		this.wipeLocalSession()
		



	}
}

export default User