import config from '../utils/config.app.js';
import React, {useState, useEffect} from 'react';
// import Nav from 'react-bootstrap/Nav';

import User from '../utils/userdata.app.js'


// import Button from 'react-bootstrap/Button';
// import ProgressBar from 'react-bootstrap/ProgressBar';
// import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import EnumeratedCheckbox from './enumerated-checkbox.component'
import {ReactComponent as CloseIcon} from './../assets/close.svg'
import {MediaPreviewGrid} from './media-preview-grid.component'
import {Spinner} from "./spinner.component";
import {dict2arr} from "../utils/fetch.utils.js";

import "./../components-css/download-modal.scss"



function DownloadModal({isOpen, content_item, handleClose, onDownload, flashMessageCallback})
{

		
	// console.log('citem', content_item);
	var [selectedFiles, setSelectedFiles] = useState([])

	useEffect(() => {
		if (content_item !== null)
		{
			setSelectedFiles([Object.keys(content_item.slides)[0]])
		}
		
	},[content_item])



	const onSelect = (slide, isSelected)=>{

		// console.log('onSelect', slide.slide_key, isSelected)

		if (isSelected)
		{
			// console.log('Selecting..', slide.slide_key)
			setSelectedFiles([...selectedFiles,slide.slide_key])
		}
		else
		{
			// console.log('DESelecting..', slide.slide_key, selectedFiles, selectedFiles.filter((item)=>item===slide.slide_key ))
			setSelectedFiles(selectedFiles.filter((item)=>item!==slide.slide_key ))
			

		}
	}

	const downloadButtonHandler = (e) =>{

		// console.log('selected', selectedFiles);
		downloadFiles(selectedFiles);
		flashMessageCallback("Download in progress!")
		handleClose();

	}

	function downloadFiles(selectedFiles) {
		var user = new User();
	    let url = config.downloadServiceUrl +  
	    	"?session_id=" + user.getSessionID() + 
	    	"&post=" + content_item.key

	    onDownload(selectedFiles, content_item);
	    for (let i=0;i<selectedFiles.length;i++)
	    {

	    	url += '&slides='+selectedFiles[i]
	    }
		window.location.href = url;
		  
		// setTimeout( ()=>handleClose(null), 1000);
	}




	const thumbnailRenderer = (item)=> {
		// console.log('thumbnailRenderer', item)
		return (
			<>
				<div className={item.is_downloaded ? 'faded': ''}>
					<img 
						
						src={item.placeholder? item.miniature_url :  config.contentServiceUrl+item.miniature_url} 
						style={{width:'100%'}} 
					/>
					{item.placeholder 
					? <Spinner/>
					: (
						<EnumeratedCheckbox 
							onChange={(state)=>onSelect(item, state)}
							selected={selectedFiles.filter((i)=>i==item.slide_key).length>0}
							text={selectedFiles.indexOf(item.slide_key)+1}
						/>
					)}
					
				</div>
			</>
			);
	}

	const prepareGridItems = (items)=>{


		return items.map((item)=>{return {...item, key:item.slide_key}})
	}

	const selectAll = (slides)=>{


		if (selectedFiles.length == slides.length)
		{
			setSelectedFiles([]);
		}
		else
		{
			setSelectedFiles( slides);
		}
	}
	if (content_item==null)
	{
		return null;
	}


	return (
		    <>
		      <Modal 
		      	className="download_modal"
		      	show={isOpen} 
		      	dialogClassName="small-modal"
		      	fullscreen={false}
		      >
		        <Modal.Header onHide={handleClose}>
		          <div className="select-all">

		          	<a 
		          		href="#"
		          		onClick={()=>selectAll( Object.keys(content_item.slides)  )}
		          		className={Object.keys(content_item.slides).length < 2 ? 'hidden' : ''}
		          	>
			          	<EnumeratedCheckbox 
							onChange={()=>{}} //{(state)=>onSelect(item, state)}
							selected={content_item && selectedFiles.length == dict2arr(content_item.slides).length} //{selectedFiles.filter((i)=>i==item.slide_key).length>0}
							text="" //{selectedFiles.indexOf(item.slide_key)+1}
						/>
						<span>Select all</span>
					</a>



		          </div>
		          <h3>Download</h3>
		          <button className="btn-close" onClick={handleClose}><CloseIcon/></button>
		        </Modal.Header>
		        <Modal.Body>
		        	<MediaPreviewGrid 
						items={!!content_item ? prepareGridItems(dict2arr(content_item.slides)): []} 
						thumbnailRenderer={thumbnailRenderer}
					/>
		        </Modal.Body>
		        <Modal.Footer>
		          <button 
		          	className="button button-primary" 
		          	onClick={downloadButtonHandler}
		          >
		            Download
		          </button>
		        </Modal.Footer>
		      </Modal>
		    </>
		  );
} 



export {DownloadModal}

