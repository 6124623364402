import React, {useState, useRef, useEffect, memo} from 'react';


// import React, {state, useState, useEffect} from 'react';
// import {Routes, Route, Navigate, BrowserRouter as Router, redirect} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';

// import config from '../utils/config.app.js';
import {ReactComponent as HomeHlIcon} from './../assets/home-hl.svg'
import {ReactComponent as HomeIcon} from './../assets/home.svg'
import {ReactComponent as HomeAltIcon} from './../assets/home-alt.svg'
import {ReactComponent as HomeHlAltIcon} from './../assets/home-hl-alt.svg'

import {ReactComponent as DMIcon} from './../assets/dm.svg'
import {ReactComponent as DMAltIcon} from './../assets/dm-alt.svg'
import {ReactComponent as DMHlIcon} from './../assets/dm-hl.svg'
import {ReactComponent as DMHlAltIcon} from './../assets/dm-hl-alt.svg'


import {ReactComponent as AddProjectIcon} from './../assets/add-project.svg'

import {checkForNewDMs} from '../services/user-service.js';
import User from "../utils/userdata.app.js";
import "./../components-css/bottom-nav.scss"





	

	



function BottomNav({onAddClick=()=>{}, homeUrl="#", dmUrl="#", hideOnModal=true })
{
	let [hasNewDMs, setHasNewDMs] = useState(true);





	const checkForNewDMsWrapper = ()=>
	{
		let user = new User();
		if (!user.getSessionID()) return;
		checkForNewDMs(user.getSessionID()).then((response)=>{
			setHasNewDMs(response.count > 0 || true);

			if (response.count > 0)
			{
				console.log('you have new messages!', response);
			}
		},[])	
	}




	useEffect(()=>{
		
		let interval = setInterval(checkForNewDMsWrapper, 1000 * 10);
		return ()=>{
			clearTimeout(interval);
		}

	})


	// desktop/mobile - current section - has new messages



/*

Not DM section
	- Occasionaly check for /dm/new

DM section
	- 	Stop checking for new DMs
	- 	Occasionally reload dms feed
	- 	Introduce a 1 min gap between seen and read

		


*/




	const DMsIcon = ({ active = false}) =>{

		// console.log('DMsIcon', hasNewDMs, active)

		return (
			<>
				<i className="desktop-only">
					{active 
						? <DMHlIcon/> 
						: <DMIcon/>  

					}
				</i>
				<i className="mobile-only">
					{active 
						? <DMHlAltIcon/> 
						: <DMAltIcon/>  

					}
				</i>
			</>
		);

		

	}


	const HomeIconComponent = ({active = false}) =>{

		// console.log('DMsIcon', hasNewDMs, active)

		return (
			<>
				<i className="desktop-only">
					{active 
						? <HomeHlIcon/> 
						: <HomeIcon/>  

					}
				</i>

				<i className="mobile-only">
					{active 
						? <HomeHlAltIcon/> 
						: <HomeAltIcon/>  

					}
				</i>

			</>
		);

		

	}


				

	return (
		<div className={"bottom-nav"+(hideOnModal ? ' hide-on-modal' : '')}>
					


			<div className="menu-item home-link">
				<a href={homeUrl} className="menu-item">
					<HomeIconComponent active={window.location.pathname == homeUrl }/>
					
					<span className="text-small">Home</span>
				</a>
			</div>
			<div className="menu-item add-link">
				<a href="#" onClick={onAddClick}>
					<AddProjectIcon/>
				</a>
			</div>

			<div className={"menu-item dm-link "+ (hasNewDMs ? 'bubble': '')}>
				<a href={dmUrl} className="menu-item">

					<DMsIcon active={window.location.pathname == dmUrl }/>
					<span className="text-small">DM</span>
				</a>
			</div>




						
				
		</div>



	
		)
}




export {BottomNav}

