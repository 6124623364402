import {TextContentPage} from '../components/text-content-page'

const PrivacyPolicyText = ()=>{
	return (
		<>

		<h1>Ye</h1>
		<p>
		Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et congue nunc. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Curabitur vehicula elit quis elit iaculis hendrerit. Suspendisse et nunc a leo pulvinar feugiat eu at nibh. Maecenas in arcu justo. Cras lacinia imperdiet efficitur. Maecenas mollis nisl sit amet sapien malesuada ultricies. Duis cursus arcu vitae velit commodo, at ultricies erat euismod. Phasellus imperdiet posuere turpis ut vulputate. Fusce massa nisi, facilisis a risus eu, feugiat vehicula lorem. Maecenas auctor quam vel lobortis suscipit. Duis facilisis tristique magna, quis fermentum felis euismod sed. Suspendisse potenti.
		</p>
		<h3>Hololooo</h3>
		<p>
		Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et congue nunc. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Curabitur vehicula elit quis elit iaculis hendrerit. Suspendisse et nunc a leo pulvinar feugiat eu at nibh. Maecenas in arcu justo. Cras lacinia imperdiet efficitur. Maecenas mollis nisl sit amet sapien malesuada ultricies. Duis cursus arcu vitae velit commodo, at ultricies erat euismod. Phasellus imperdiet posuere turpis ut vulputate. Fusce massa nisi, facilisis a risus eu, feugiat vehicula lorem. Maecenas auctor quam vel lobortis suscipit. Duis facilisis tristique magna, quis fermentum felis euismod sed. Suspendisse potenti.
		</p>
		<p>
		Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et congue nunc. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Curabitur vehicula elit quis elit iaculis hendrerit. Suspendisse et nunc a leo pulvinar feugiat eu at nibh. Maecenas in arcu justo. Cras lacinia imperdiet efficitur. Maecenas mollis nisl sit amet sapien malesuada ultricies. Duis cursus arcu vitae velit commodo, at ultricies erat euismod. Phasellus imperdiet posuere turpis ut vulputate. Fusce massa nisi, facilisis a risus eu, feugiat vehicula lorem. Maecenas auctor quam vel lobortis suscipit. Duis facilisis tristique magna, quis fermentum felis euismod sed. Suspendisse potenti.
		</p>
		</>
	)
}


export const PrivacyPolicy = ({})=>
{


	return (

		<TextContentPage 
			menuItems={[
				['Terms of service', '/tos'],
				['Contact', '/contact']
			] }
			content={<PrivacyPolicyText/>}
		/>
	);
}