import React, {useRef, useState, useEffect} from 'react';
import {Routes, Route, Navigate, BrowserRouter as Router, redirect} from 'react-router-dom';

import {SignInWithGoogle} from '../components/sign-in.component'




import Modal from 'react-bootstrap/Modal';

import User from '../utils/userdata.app.js';

import checkIfSuccessful from '../utils/fetch.utils.js';
import {ReactComponent as PineappelIcon} from './../assets/logo.svg'
import {ReactComponent as CheckedIcon} from './../assets/checked.svg'
import { TopNav } from "../components/top-nav.component";
import { BottomNav } from "../components/bottom-nav.component";

import { FlashMessage } from "../components/flash-message.component";
import { Input } from "../components/form.input.component";


import {saveNickname, validateSession} from '../services/user-service.js'
import {Spinner} from "../components/spinner.component";

import {ContentActions} from '../components/content-feed/content-actions'

import config from '../utils/config.app.js'

import "./../components-css/landing-page.scss"

/* Log in to Pineappel */





function LandingPage() {


	var [loginStep, setLoginStep] = useState(0); // 0 - google, 1 - set nickname, 2-proceed to /home, 10 - waiting for session validation
	var [userData, setUserData] = useState({});
	// var [showTOSError, setShowTOSError] = useState(false)

	

	const onSuccessfulLogin = (data)=>
	{
		let user = new User(data)
	    if (data.success)
	    {
	    	// console.log('Userdata', data);
	    	setUserData(data);
	    	let user= new User(data)
	        setLoginStep(data.first_login ? 1 : 2);	      
	    }
	    else
	    {
	      console.error('something wrong with the response', data)
	    }
	}

	
	

	const LoginButtonDialog = ({onSuccessfulLogin})=>
	{
		return (
			<>
				<h1>Log in to <PineappelIcon /> Pineappel</h1>
				<SignInWithGoogle onSuccess={onSuccessfulLogin} className="google-login" />
			</>
		);
	}




	const SetNicknameDialog = ({defaultValue, session_id, onNicknameSubmitted })=>
	{
		let [tosChecked, setTosChecked] = useState(false);
		// let [valueInState, setValueInState] = useState('');
		var [flashMessage, setFlashMessage] = useState({message:"", timeout:"", kind:"alert" });
		let nicknameField = useRef(null)
		
		const flashMessageCallback = (message, timeout, kind)=>
		{
			setFlashMessage({message:message, timeout:timeout, kind:kind, show:true, rnd:new Date().getTime()})
		}

		const submitForm = (nickname, nicknamePattern, tosChecked, session_id)=>{
			// console.log(nickname,tosChecked)

			// if (nickname.trim()=='')
			// 	nickname = defaultValue;

			// console.log(nickname);
			if (new RegExp(config.nicknamePattern).test(nickname) !== true)
			{
				onInputFormatInvalid(null);
				return
			}


			// console.log('nickname',nickname, session_id);
			let user = new User()
			if (tosChecked)
			{
				saveNickname(nickname, session_id)
				.then(setLoginStep(2))
				user.setProperty('name', nickname);
			}
			else
			{
				// setShowTOSError(true)

				flashMessageCallback('You must agree to the TOS!', 2000, "alert")

			}

		}

		const onInputFormatInvalid = (event)=>{

			flashMessageCallback('Invalid nickname!', 2000, "alert")
		}


		return (
			<>
				<h1>Create nickname</h1>
				<FlashMessage {...flashMessage} />
				<form 
					className="nickname-form"
					onSubmit={(event)=>{event.preventDefault(); return false;} }
				>
					<Input
						validationErrorMsg="Nickname must be 3-8 symbols long and contain only digits and letters"
						subText = "You can change it later"
						inputProps={{
							ref: nicknameField,
							onInvalid: onInputFormatInvalid,
							defaultValue : '',
							// onChange: (event)=>setValueInState(event.target.value),
							placeholder : defaultValue.replace(config.nicknameReplacePattern, '').slice(0,8),
							type : "text",
							pattern: config.nicknamePattern
						}}
					/>

					<div className="checkbox">
						<div 
							onClick={()=>setTosChecked(!tosChecked)}
							className={"check" + ( tosChecked ? ' checked ': '') }
						>
							{tosChecked ? <CheckedIcon/> : null}
						</div>
						<span>
							I agree to Pineappel <a href="/terms-of-service">terms of service and privacy policy</a>
						</span>
					</div>
					<button 
			          className="button button-primary full-width" 
			          type="submit"
			          onClick={()=>{
			          			if (nicknameField.current.value === '')
			          					nicknameField.current.value = defaultValue.replace(config.nicknameReplacePattern, '').slice(0,8)
			          			submitForm(
			          				nicknameField.current.value, 
			          				//defaultValue.replace(config.nicknameReplacePattern, ''), 
			          				config.nicknamePattern,
			          				tosChecked, 
			          				session_id)
			          			}
			          		}
			        >Log in</button>
				</form>
			</>

		);
	}

	const WaitForSessionValidation = ({onSuccessfulValidation, onFailure})=>{




		return (
			<>
				<h1>Log in to <PineappelIcon /> Pineappel</h1>
				<Spinner/>
			</>
		);
	}



	useEffect(()=>{

		if (loginStep==2)
		{
			window.location = '/home'
		}

	},[loginStep])



	useEffect(()=>{
		let user = new User()


		if ((loginStep == 0) && user.hasSessionData())
		{
			console.log('Session exists')
			setLoginStep(10)
			validateSession(user.getSessionID()).then((result)=>{
				if (result.success === true)
				{
					console.log('session is valid..', user)
					result.data.success = true
					onSuccessfulLogin(result.data)
				}
				else
				{
					console.log('session is not valid..',result)
					user.wipeLocalSession();
					setLoginStep(0)
				}
				
			})

		}


	})


	return (

		<div className="landing-page"> 

			<TopNav /> 

			<div className="background-video">
				<video 
					className="large"
					src="/videos/landing-page-2.mov" 
					autoPlay={true} 
					playsInline
					loop 
					muted
				/>

				<video 
					className="small"
					src="videos/landing-page-2-mob.mov" 
					autoPlay={true} 
					playsInline
					loop 
					muted
				/>



			</div>

			<div className="overlay">
				<ContentActions/>

			</div>
			
			

			<Modal 
				className="small-modal login-modal" 
				show={true}
				centered
			>
		        <Modal.Body>
		          <div className="hero-dialog">
					{
						loginStep == 0 ? <LoginButtonDialog onSuccessfulLogin={onSuccessfulLogin}/> : null 
					}
					{
						loginStep == 1 ? <SetNicknameDialog defaultValue={userData.name} session_id={userData.session} onNicknameSaved={()=>{}} /> : null
					}
					{
						loginStep == 10 ? <WaitForSessionValidation onSuccessfulLogin={onSuccessfulLogin}/> : null 
					}
									
				</div>
		        </Modal.Body>

	      	</Modal>
			<BottomNav  hideOnModal={false}/>
		</div>

	);
}

  

export default LandingPage;



