import React, {state, useState, useEffect} from 'react';
import "./../components-css/flash-message.scss"
import {ReactComponent as AlertMsgIcon} from './../assets/alert-msg.svg'
import {ReactComponent as SuccessMsgIcon} from './../assets/success-msg.svg'


export const FlashMessage = ({message, timeout=2000, rnd, show=true, children=null, kind="success"})=>
{
	var [timerRef, setTimerRef] = useState(null);
	var [visible, setVisible] = useState(show);



	useEffect(()=>{

		if (show)
		{
			setVisible(true)
			// console.log('useEffect', show, visible)
			timerRef = setTimeout(()=>{

				setVisible(false)

			}, timeout );
		}
		

	},[children, message, rnd])
	// if (!showing) return null;

	return (
		<div 
			className={"flash-message" + ( visible ? ' show ' : '')}
			hidden={!show}
		>
			{children}
			<i className="icon">
				{kind == "alert" 
					? <AlertMsgIcon/>
					: <SuccessMsgIcon/>
				}
			</i>
			
			<span className="message">{message}</span>}
		
		</div>
	)
}