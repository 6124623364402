
import '../components-css/user-bar.scss'
import {ReactComponent as RemoveIcon} from './../assets/remove.svg'

import {AvatarComponent} from './avatar.component'




export const UserBar = ({items, onClick, showRemove=false, count=5})=>{
	

	return (
		<div className="user-bar">
		{items.slice(0,count).map(item=>{
			return (

				<AvatarComponent
					key={item.user_key}
					image={item.user_avatar}
					name={item.name}
					onClick={(e)=>onClick(item)}
					showName={true}
					showRemove={showRemove}
					showBadge={true}
				/>
				// <a 
				//   	key={item.user_key}
				// 	className="item" 
				// 	href="#"
				// 	onClick={(e)=>onClick(item)}
				// >
				// 	<div className="avatar">
				// 		<img src={item.user_avatar} alt={item.name}/>
						
				// 	</div>
				// 	{showRemove ? <div className="remove"><RemoveIcon/></div>:null}
				// 	<span className="name text-xsmall">{item.name}</span>
				// </a>
			);

		})}
		</div>


	);
}