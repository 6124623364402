import config from '../../utils/config.app.js';
import React, {useState, useRef, useEffect} from 'react';
// import Nav from 'react-bootstrap/Nav';

import User from '../../utils/userdata.app.js'


// import Button from 'react-bootstrap/Button';
// import ProgressBar from 'react-bootstrap/ProgressBar';
// import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import {UploadSplash} from './upload-splash'
import {UploadDetails} from './upload-details'
import {ReactComponent as CloseAltIcon} from './../../assets/close-alt.svg'

// import DragNdrop from './drag-n-drop.component'

import {MediaPreviewGrid} from './../media-preview-grid.component'
import {CopyButton} from '..//copy-button.component';
import {FlashMessage} from '..//flash-message.component'
// import {ReactComponent as FileIcon} from './../assets/file.svg'
import {checkIfSuccessful} from "../../utils/fetch.utils.js";
import {SelectFiles} from './select-files'


import {generateThumbnails} from '../../utils/generate-thumbnails.js';

import {dict2arr} from "../../utils/fetch.utils.js";



import {removeSlide} from '../../services/post-service.js'; 
import "./scss/upload-modal-alt.scss"



function UploadModal({
			open=false, 
			centered=true,
			handleClose, 
			onCollabCreated,
			returnCollabAsync=true, // should onCollabCreated be called async without waiting for the final confirmation from the user?
			uploadTo=null,
			welcomeH1="Drag your files here, or",

			modalH3="Create new collab",
			flashMessageCallback,
			showDescriptionAndInvites=true



		})
{


	var [uploadStep, setUploadStep] = useState(0);
	// var [files, setFiles] = useState([]);
	// var [uploadPlaceholders, setUploadPlaceholders] = useState([]);
	// var [uploadResponseObj, setUploadResponseObj] = useState(null);
	//var [uploadInProgress, setUploadInProgress] = useState(false);
	// content template to be populated with uploads and chats
	var [contentObj, setContentObj] = useState(uploadTo);
	var [waitingForCollab, setWaitingForCollab ] = useState(false);



	// useEffect(()=>{

	// 		window.onbeforeunload = ()=>{

	// 			if (uploadPlaceholders.length > 0)
	// 			{
	// 				return "Uploads in progress";
	// 			}
	// 		}
	// 		// window.addEventListener("beforeunload", onTabClose);

		
	// })




	async function postData(body, uploadTo)
	{
		var t0 = performance.now()
		let user = new User()
		let url = config.contentUploadServiceUrl+'?session_id='+user.getSessionID();
		var size = Array.from(body).map(a=>a[1].size || a[1].length).reduce((acc, cur)=>acc+cur, 0)
		// console.log(body);
		
		// /upload_content_alt/<string:upload_to>

		return fetch(
			config.contentUploadServiceUrl+'/'+uploadTo+'?session_id='+user.getSessionID(),
			  // 'http://localhost:5556/upload_content_alt/'+
			  {
			  	body: body,
			  	method: "POST",
		        mode: "cors", // no-cors, *cors, same-origin
		        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
		        redirect: "follow",
			  })
			.then((response) => response.json())
			.then((response)=> {
				response.post.just_updated = true
				let time = performance.now() - t0;
				console.log('Uploading',size,', took', time / 1000, 'seconds, that is', Math.round(size/time*1000/1024/1024*100)/100,'Mb/s')
				return response;
			});
	}





	const filterPlaceholders = (placeholders, post)=>{

		// console.log('post.slides',post.slides)
		

		// here we remove from placeholders all items that have a matching slide in post.slides

		let realSlides = dict2arr(post.slides).filter((slide)=>!slide.placeholder).map((slide)=> slide.original_filename);



		// console.log('filterPlaceholders', placeholders.map(p=>p.filename), realSlides);


		// console.log('----- YE filtering ------');
		for (let iP = 0;iP<placeholders.length;iP++)
		{
			console.log(placeholders[iP].filename)
			console.log(realSlides, realSlides.indexOf(placeholders[iP].filename))


		}

		return placeholders.filter((p)=> realSlides.indexOf(p.filename)==-1 )
	}



	const updateFeedAfterUpload = (response, uploadPlaceholders)=>{
		// remove all placeholders where filename in response files.
		// console.log('files', response.files);
		// console.log('placeholders before', uploadPlaceholders)
		let updatedPlaceholders = filterPlaceholders(uploadPlaceholders, response.post)
		// console.log('placeholders after', updatedPlaceholders)
		// setUploadPlaceholders(updatedPlaceholders)


		// console.log('upload done', response)
		setContentObj(response.post);
		

		let post = response.post;

		post.slides = {...post.slides, ...createSlidesFromPlaceholders(updatedPlaceholders, post.key)}

		// console.log('another upload has finished, revising post.slides', updatedPlaceholders, post.slides )

		setContentObj(response.post);
		onCollabCreated(post); 
		

	}

// sfjetc1iGsXDZRrEHjZl




	const startUpload = (files, uploadTo,uploadPlaceholders )=>
	{


		var user = new User()
		
		// uploading each file individually
		for (let i=0;i<files.length;i++)
		{
			// console.log('starting upload: ' , i)
			let formData = new FormData()
			

			// this must always be present..
			formData.append('uploadto', uploadTo.key)
			formData.append("file"+i, files[i])


			postData( formData, uploadTo.key)
			.then((response)=> updateFeedAfterUpload(response, uploadPlaceholders));
		}

			

		
	}




	const createEmptyCollab = (args)=>{
		var user = new User();
	    return fetch(
	      	config.createCollabServiceUrl +
			"?session_id=" +
			user.getSessionID(),
			  {
			    method: "POST",
			    mode: "cors", // no-cors, *cors, same-origin
			    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			    redirect: "follow",
			    headers: {
			      Accept: "application/json",
			      "Content-Type": "application/json",
			    },
			    body: JSON.stringify(args || {}),
			  }
			)
			.then((response) => response.json())
			.then((response) =>
			checkIfSuccessful(
			  response,
			  // if the post was successful
			  function (r) {


			  	return r;

			  },
			  function (r) {
			    console.error("something was wrong..");
			  }
			)
			);


	}


	const prepareContentKey = (uploadTo, args)=>{
		if (uploadTo === null)
		{
			// console.log('creating new content key', args)
			return createEmptyCollab(args).then((r)=> {

				// console.log('content obj = ', r);
				setContentObj(r.post);
				return r.post;
			})
		}
		else
		{
			// console.log('reusing current post', uploadTo)
			return new Promise((resolve, reject)=>resolve(uploadTo)).then((uploadTo)=>{

				// console.log('content obj is the same as uploadTo = ', uploadTo);
				setContentObj(uploadTo);
				return uploadTo;
			});
			

			
		}
	}


	const createSlidesFromPlaceholders = (placeholders, key)=>
	{

		let placeholderSlides = {};
		for (let i=0;i<placeholders.length;i++)
		{
			placeholderSlides[ placeholders[i].filename ]={

				slide_key 			: placeholders[i].filename,
				content_url 		: null,
				duration			: 0,
				filename			: placeholders[i].filename,
				is_downloaded		: false,
				miniature_url 		: placeholders[i].src,
				original_filename 	: placeholders[i].filename,
				post_key			: key,
				preview_url 		: null,//placeholders[i].src, // this does not work..sssssssss
				thumbnail_url		: placeholders[i].src,
				timeline_reactions  : {}, 
				placeholder 		: true
			}
		}
		return placeholderSlides;

	}



	const prepAndUploadFiles = (newFiles)=>
	{
		// setFiles([...files, ...newFiles])

		console.log('prepAndUploadFiles', newFiles);
		setWaitingForCollab(true);



		generateThumbnails(Array.from(newFiles), 1, 500 ).then((sources, i)=>{

			console.log('prepAndUploadFiles : generateThumbnails promise', sources)
			
			let placeholders = sources.map((t,i)=> { return {src:t.src, filename : t.filename, index:i}});

			// console.log('setting placeholders to', [...placeholders, ...uploadPlaceholders])

			// setUploadPlaceholders( [...uploadPlaceholders, ...placeholders] )


			
			prepareContentKey(contentObj , {slides_count:newFiles.length}).then((response)=>{
				response.slides = {...response.slides, ...createSlidesFromPlaceholders(placeholders, response.key)}
				onCollabCreated(response);
				startUpload(newFiles, response, placeholders);
				setWaitingForCollab(false);
				setUploadStep(1);

			})



		});




			// 

		


	}


	const onRemoveSlide = (slide_key)=>
	{

		var user = new User()
		return removeSlide(contentObj.key,slide_key, user.getSessionID()).then(()=>{

			let newContentObj = {...contentObj};
			delete newContentObj.slides[slide_key];
			setContentObj(newContentObj);
			
		})



		// console.log('onRemoveSlide', slide_key)
	}





	const RenderStep=(step)=>
	{
		switch (step)
		{
			case 0:
				return <UploadSplash 
							onFilesDropped={(files)=>{
									prepAndUploadFiles(files)
								}
							}
							welcomeH1={welcomeH1}
							cancelCallback={handleClose}
							isLoading={waitingForCollab}
						/>;
			case 1:
				return <UploadDetails 
							// uploadPlaceholders={uploadPlaceholders} 
							uploadTo={contentObj}
							flashMessageCallback={flashMessageCallback}
							onRemoveSlide={onRemoveSlide}
							showDescriptionAndInvites={showDescriptionAndInvites}

							// onFilesUploaded={onFilesUploaded} 
							triggerNextStep={(response)=>{
									// console.log('case 1', response)
									// setUploadResponseObj(response);
									onCollabCreated(response); 
									handleClose();
								}
							}
						/>;
		}


	}



	const nextStep = ()=> setUploadStep(uploadStep+1)
	return (
		<>
			

			<Modal 
			      	className="upload-modal"
			      	dialogClassName={"small-modal step-"+(uploadStep) }
			      	show={open} 
			      	fullscreen={false}
			      	// onShow={prepareContentKey}
			>

			      <Modal.Header onHide={handleClose}>
			      <h3>{modalH3}</h3>
			      <button className="btn-close" onClick={handleClose}><CloseAltIcon/></button>
			      </Modal.Header>
			      <SelectFiles 
						onFilesSelected={prepAndUploadFiles}
						id="selectFilesElement" 
					/>
			      {RenderStep(uploadStep)}
			      

			</Modal>
		</>

		)
}

export {UploadModal}