
import Carousel from 'react-elastic-carousel'

import "./../components-css/double-slider.scss"




export const DoubleSlider = ()=>{



	const CarouselTB = ({n=10})=>{

		return (

		<Carousel 
			itemsToShow={1} 
			verticalMode={true} 
			className="vertical"
			pagination={false}
			showArrows={false}
		>
		  {Array(n).fill(0).map((_, j) =>{
		  	return (
		  		<CarouselLR n={10} key={j}/>
		  	);


		  })}
		</Carousel>)

	}


	const CarouselLR = ({n=10})=>{

		return (<Carousel itemsToShow={1}>
		  {Array(n).fill(0).map((_, j) =>{
		  	return (
		  		<div className="slide" key={j}>
		  			
		  			<span>{j}</span>
		  		</div>
		  	);


		  })}
		</Carousel>)

	}


	return (
		<div className="double-slider">
		<CarouselTB n={10}/>
		</div>
	);
}