import React, {useState, useRef, createRef, useEffect, memo} from 'react';


import {VideoPlayer} from './video-player'

import Carousel from 'react-elastic-carousel'

import {PostComponent} from './post';
import {InvitationComponent} from './invitation';

import {dict2arr} from "../../utils/fetch.utils.js";

import "./scss/feed.scss"

export const FeedComponent = ({items, controlsCallback, defaultActiveSlide = 0 , currentlyActiveItem,currentOpenChat,toggleChatCallback,chatMessageSubmitHandler,contentActionsClickHandler,invitesHandler})=>{


	let activeSlide = 0;
	const params = new URLSearchParams(window.location.search);



	const onCarouselChange = (carouselSlide, index)=>
	{
		// console.log(post, slide, slideIndex)
		//setActiveSlideIndex(slideIndex);
		controlsCallback(items[index], null, 'post-change', {slideIndex:index})

		// console.log('onCarouselChange', carouselSlide, index)
	}


	

	if (items.length == 0) return null;


	let canSwipeUpAndDown = currentOpenChat === null;
	return (
		<div
			// ref={feedContainerRef}
			className="feed-container"
		>

			<Carousel 
				itemsToShow={1} 
				verticalMode={true} 
				className="vertical"
				initialActiveIndex={defaultActiveSlide}
				pagination={false}
				showArrows={false}
				// enableMouseSwipe={canSwipeUpAndDown}
				// enableSwipe={canSwipeUpAndDown}
				// enableMouseSwipe={currentOpenChat === null}
				// enableSwipe={currentOpenChat === null}
				onChange = {(carouselSlide, index)=>onCarouselChange(carouselSlide, index) }
			>

				
				{items.map((item, i)=>{


					if (item.is_invitation)
						return (
							<InvitationComponent 
								// ref={(el)=>refFunc(item, el) }
								key={item.key}
								item={item}
								invitesHandler={invitesHandler}
								controlsCallback={controlsCallback}
								currentlyPlaying={ currentlyActiveItem[0]==item.key ? [true, currentlyActiveItem[1] ] : [null, null]   }
							/>
						)

					else
					{

						if ((params.get('post_key') == item.key) && params.has('slide_key'))
						{

							let slidesArr = Object.keys(item.slides);
							activeSlide = slidesArr.indexOf(params.get('slide_key'));

							// console.log('activeSlide', activeSlide, slidesArr);
						}

						return (
							<PostComponent
								// ref={getRef }
								// ref={(el)=> postRefs.current[i]  = el }
								key={item.key}
								item={item}
								controlsCallback={controlsCallback}
								defaultActiveSlide={activeSlide}
								// make sure we always pass [bool, bool] to avoid 
								// re-renders whenver something irrelevant chages
								currentlyPlaying={ currentlyActiveItem[0]==item.key ? [true, currentlyActiveItem[1] ] : [null, null]   }
								chatOpen={currentOpenChat == item.key}
								toggleChatCallback={(open)=>toggleChatCallback(item.key, open)}
								chatMessageSubmitHandler={chatMessageSubmitHandler}
								contentActionsClickHandler={contentActionsClickHandler}

							/>
							)
					}
				} )}
			</Carousel>
			

		</div>

		)
}