
import React, {useState, useEffect, useRef} from 'react';
import {ReactComponent as RemoveIcon} from './../assets/remove.svg';
import {ReactComponent as PremiumBadgeIcon} from './../assets/premium-badge.svg';

import '../components-css/avatar.scss';

export const AvatarComponent = ({
	image="", 
	name=" ",
	onClick=()=>{},
	showName = false, 
	showRemove=false,
	children=[]
}) => {

	let [isAvatarError, setAvatarError] = useState(false)


	const onError = (error)=>{

		// console.log('error', error);
		setAvatarError(true)
	}


	return (
		<a 
			className="avatar" 
			href="#"
			onClick={onClick}
		>
			<div className="avatar-image">
				

				{isAvatarError 
				? (

					<span className="initials">
						{name.slice(0,1)}

					</span>
						
				)

				:(
					<img 
						src={image}
						alt={name}
						onError={onError}
					/>

				 )
				}
				
				
			</div>
			{showRemove ? <div className="remove"><RemoveIcon/></div>:null}
			{showName ? <span className="name text-xsmall">{name}</span>:null }
			{children}
		</a>

	);
}