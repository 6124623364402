import React, {useState, useEffect, useRef} from 'react';
// import Home from './page/Home';
// import Signup from './page/Signup';
// import Login from './page/Login';
// import {SignIn} from './components/sign-in.component'



import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { TopNav } from "../components/top-nav.component";
import { BottomNav } from "../components/bottom-nav.component";
import { MediaPreviewGrid } from "../components/media-preview-grid.component";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./../components-css/grid-view.scss"

import User from "../utils/userdata.app.js";
import config from "../utils/config.app.js";
import {dict2arr, checkIfSuccessful} from "../utils/fetch.utils.js";

import {ReactComponent as PlayIcon} from './../assets/play.svg'

import { UploadModal } from "../components/upload-modal/upload-modal-alt.component";
// import { BottomNav } from "../components/bottom-nav.component";


// https://andrewraycode.github.io/easing-utils/gh-pages/
// import 'easing-utils/dist/easing.js'


// / Curly braces needed for import
// import {linear, easeOutBack,easeOutSine,easeInElastic, easeOutElastic,easeInQuart,easeOutQuart,easeInSine, easeInCubic, easeInOutBounce } from 'easing-utils';

// Pass in a value from 0 - 1 and get the eased value back


const onGridItemClick = (post) =>
{
	// console.log(post)
	let slides = dict2arr(post.slides);
	document.location.href = '/home'
		+'?post_key=' + post.key
	// alert('something happens here');
}
const GridItem = (post, isNew, onClick)=>{
	
	console.log('post is empty' ,post); 
	let slides = dict2arr(post.slides);

	return (
			<a 
				className="grid-item"
				href="#" 
				onClick={(event)=>onGridItemClick(post)}
			>
				<img 
					src={config.contentServiceUrl+slides[0].thumbnail_url}
				/>
			</a>

			
		)
}

export default function GridView() {

	var user = new User();
	var [feed, setData] = useState({ items: [] });
	var [uploadModalOpen, setUploadModalOpen] = useState({open:false, uploadTo:null});


	function onUploadDone(post, uploadTo) 
	{

		

		// in the grid view,
		// it is always a new post..

		var newItems = [post, ...feed.items];
		setData({ items: newItems });  


	}


	 function fetchFeedData(user) {
	    return fetch(
	      config.contentFeedServiceUrl + "?session_id=" + user.getSessionID(),
	      {
	        method: "GET",
	        mode: "cors", // no-cors, *cors, same-origin
	        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
	        redirect: "follow",
	        headers: {
	          Accept: "application/json",
	          "Content-Type": "application/json",
	        },
	      }
	    )
	      .then((response) => response.json())
	      .then((response) =>
	        checkIfSuccessful(
	          response,
	          function (r) {
	            return r;
	          },
	          function (r) {
	            return { items: [] };
	          }
	        )
	      );
	  }



	useEffect(() => {
	    fetchFeedData(user)
	      .then((contentItems) => {
	        setData(contentItems);
	      })
	      .then(() => {
	        // setCurrentlyPlaying(feed.items[0].slides[0].slide_key)
	      });
	  },[]);


	



	return (
		<>
			<TopNav 
		        user={user}
		        homeUrl="/home"
		        gridUrl="/grid"

		      />
			<div className="grid-view scrollable">
				<div className="mid-row">
				
					<MediaPreviewGrid
						items={feed.items}
						thumbnailRenderer={GridItem}
					/>
				</div>
		     
		    </div>
		    <BottomNav 
		    	onAddClick={() => setUploadModalOpen({open:true, uploadTo:null, key:  new Date().getTime() }) } 
		    	homeUrl="/home"
    			dmUrl="/dm"
		    />
		  <UploadModal
		  		key={uploadModalOpen.key}
		        open={uploadModalOpen.open}
		        handleClose={() => setUploadModalOpen({open:false, uploadTo:null})}
		        // onUploadDone={onUploadDone}
		        onCollabCreated={onUploadDone}
		        returnCollabAsync={false}
		        uploadTo={null}
		        welcomeH1={ uploadModalOpen.uploadTo===null ? "Start a new collab" : "Add files to a collab"}
        		modalH3={ uploadModalOpen.uploadTo===null ? "Start a new collab" : "Add files to a collab"}

		      />
	    </>)
}