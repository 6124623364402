export function timeSince(timestamp, short=false) {

	  var seconds = Math.floor((new Date() - new Date(timestamp*1000)) / 1000);


	  const fullLabels = [' years', ' months', ' days', ' hours', ' minutes', ' seconds'];
	  const shortLabels = ['y', 'm', 'd', 'h', 'min', 's'];

	  const labels = short ? shortLabels : fullLabels

	  var interval = seconds / 31536000;

	  if (interval > 1) {
	    return Math.floor(interval) +labels[0];
	  }
	  interval = seconds / 2592000;
	  if (interval > 1) {
	    return Math.floor(interval)+labels[1];
	  }
	  interval = seconds / 86400;
	  if (interval > 1) {
	    return Math.floor(interval) +labels[2];
	  }
	  interval = seconds / 3600;
	  if (interval > 1) {
	    return Math.floor(interval)+labels[3];
	  }
	  interval = seconds / 60;
	  if (interval > 1) {
	    return Math.floor(interval)+labels[4];
	  }
	  return Math.floor(seconds) +labels[5];
	}