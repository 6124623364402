import React, {useState, useRef, useEffect, memo} from 'react';

// import {VideoPlayer} from '../components/content-feed/video-player'
// import {CarouselComponent} from '../components/content-feed/carousel'
// import {PostComponent} from '../components/content-feed/post'

import {FeedComponent} from '../components/content-feed/feed'

import User from "../utils/userdata.app.js";
import config from "../utils/config.app.js";

import { TopNav } from "./../components/top-nav.component";
import { BottomNav } from "./../components/bottom-nav.component";
import { UploadModal } from "../components/upload-modal/upload-modal-alt.component";
// import { AddFilesModal } from "../components/add-files-modal/add-files-modal";
import { AddPeopleModal } from './../components/add-people.component'
import { ShareToModal } from './../components/share-to.component'
import { DownloadModal } from "../components/download-modal.component";
import { FlashMessage } from "../components/flash-message.component";

import {checkIfSuccessful, dict2arr} from "../utils/fetch.utils.js";
import {onLikeClick, deletePost} from "../services/post-service";

import {DeleteConfirmation} from '../components/content-feed/delete-confirmation';

export const HomePage = ({})=>
{
	// this holds a tuple of [post_key, slide_key]
	let [currentlyPlaying, setCurrentlyPlaying] = useState([null, null]);

	// holds post_key of currently open chat
	let [currentOpenChat, setCurrentOpenChat] = useState(null);

	let [feed, setFeed] = useState([]);


	var [uploadModalOpen, setUploadModalOpen] = useState({open:false, uploadTo:null, key: 1 });


	var [shareToModalContent, setShareToModalContent] = useState(null);
	var [downloadModalContent, setDownloadModalContent] = useState(null);
	var [addPeopleModalContent, setAddPeopleModalContent] = useState(null);
	var [contentItemToDelete, setContentItemToDelete] = useState(null);

	var [contentItemToDelete, setContentItemToDelete] = useState(null);

	var [defaultActivePost, setDefaultActivePost] = useState(0);

	// var [currentStickyPost, setCurrentStickyPost] = useState(null); // determines which post is "sticky"
	var [flashMessage, setFlashMessage] = useState({message:"", timeout:""});

	// var [autoScrollTimeout, setAutoScrollTimeout] = useState(null); // this controls the interval for sicky posts scrolling


	// var [alreadyScrollingTo, setAlreadyScrollingTo]= useState(null);
	// current scroll position
	// var [scrollY, setScrollY] = useState(0);



	var areScrollEventsPaused = useRef(false);



	useEffect(()=>{



			window.onbeforeunload = ()=>{

				if (getActiveUploads(feed).length >0 )
				{
					return "Uploads in progress";
				}
			}
			// windw.addEventListener("beforeunload", onTabClose);

			return ()=>{
				window.onbeforeunload = null;

			}
	})


  const getActiveUploads = (feed)=>{

  		let uploads = feed.filter((post)=>{

				return dict2arr(post.slides).filter(slide=>slide.placeholder).length > 0

			})
			// console.log('Uploads in progress', uploads);
			return uploads;

  }



	/*
    THis is a helper method to update posts feed.
    it takes the post key, finds the right post and applies the callback to it
    Takes care of cloning items and setState()

    returns T/F if any item was updated
  */
	function updatePost(key, found_callback, else_callback) {

		 
		var found = false;
		const newItems = feed.map((post)=> {
		  if (post.key === key) {
		    found = true;
		    return found_callback({ ...post });
		  }
		  else
		  {
		    if (else_callback)
		        return else_callback({...post})
		  }
		  return post;
		});
		setFeed(newItems);
		return found;
	}


	const onAddFileButtonClick = (content_item)=>{
		// setAddFilesModalOpen({open:true, uploadTo:null, key: ( new Date().getTime() )})
		console.log('onAddFileButtonClick', content_item)
		setUploadModalOpen({open:true, uploadTo: content_item, key: ( new Date().getTime() )})

	}

	const onLikeButtonClick = (content_item)=>{

		let user = new User();
		let is_liked = content_item.liked_by.indexOf(user.getProperty('user_key'))>-1;

		
		onLikeClick(content_item.key, !is_liked ,user.getSessionID()).then((response)=>{

			let liked_by = response.liked_by || [];
			updatePost(content_item.key,(post)=>{
				
				post.liked_by = liked_by;
				return post;
			});	

		})
		
	}

const onAddPeopleButtonClick = (content_item)=>{

	setAddPeopleModalContent(content_item);
}
	const onShareButtonClick = (content_item)=>{

		setShareToModalContent(content_item);
	}


	function contentActionsClickHandler(content_item, action) {

	    // this f. is called when one of the buttons for a post is clicked.
	    // context determines which button was pressed

	    // console.log('You interacted with: post:', content_item.key, 'and action -', action,'--' )

	    if (action === "like") onLikeButtonClick(content_item);

	    if (action === "toggle-chat") toggleChatCallback(content_item.key,  currentOpenChat!== content_item.key)

	    if (action === "share"){
	    	setCurrentOpenChat(null);
	    	onShareButtonClick(content_item);
	    }

	    if (action === "add-file"){
	    	setCurrentOpenChat(null);
	    	onAddFileButtonClick(content_item);
	    }
	    if (action === "add-people"){
	    	setCurrentOpenChat(null);
	    	onAddPeopleButtonClick(content_item);
	    } 

	    if (action === "download")
	    {
	    	setCurrentOpenChat(null);
	    	setDownloadModalContent(content_item);
	    }

	    if (action === "delete"){
	    	setCurrentOpenChat(null);
	    	onDeleteButtonClick(content_item);
	    }

	    return false;
	}

	function fetchFeedData(user) {
	  return fetch(
		  config.contentFeedServiceUrl + "?session_id=" + user.getSessionID(),
		  {
		    method: "GET",
		    mode: "cors", // no-cors, *cors, same-origin
		    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
		    redirect: "follow",
		    headers: {
		      Accept: "application/json",
		      "Content-Type": "application/json",
		    },
		  }
		)
		  .then((response) => response.json())
		  .then((response) =>
		    checkIfSuccessful(
		      response,
		      function (r) {
		        return r.items;
		      },
		      function (r) {
		        return [];
		      }
		    )
		  );
	}

	const saveReaction=(slide, position, reaction)=>
	{
	return fetch(
	  config.reactionServiceUrl+'/'+ slide.post_key+'/'+slide.slide_key + "?session_id=" + user.getSessionID(),
	  {
	    method: "POST",
	    mode: "cors", // no-cors, *cors, same-origin
	    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
	    redirect: "follow",
	    headers: {
	      Accept: "application/json",
	      "Content-Type": "application/json",
	    },
	    body : JSON.stringify({
	      reaction:  reaction,
	      metadata : {
	        context: 'timeline_reaction',
	        position: position
	      }

	    })
	  }
	)
	.then((response) => response.json())
	  

	}


	const controlsCallback=(post, slide, context, params)=>
	{

		// close the chat if user attempts to 
		// interact with the post
		setCurrentOpenChat(null);
		

		// console.log('controlsCallback', post, slide, context, 'params:',params);
		if (context == 'play')
		{
			// console.log('play..', [post.key, slide.slide_key])
			setCurrentlyPlaying( [post.key, slide.slide_key]);


		}
		if (context == 'pause')
		{
			setCurrentlyPlaying([null, null]);
			// console.log('pause..', slide.slide_key);
		}

		if (context=='timeline_reaction')
		{
			let reaction = {
				dict_key: ('key_'+(new Date().getTime())), // something semi unique..
				position: params.position,
				reaction: params.reaction,
				user : user.getProperty('user_key','')

			}
			updatePost(slide.post_key, (item)=>{
				let reactions = {...item.slides[slide.slide_key].timeline_reactions}
				reactions[reaction.dict_key] = reaction
				item.slides[slide.slide_key].timeline_reactions = reactions
				return item
			});

			// console.log('saving reaction', reaction )
			saveReaction(slide, params.position, params.reaction);
		}

		if (context=='carousel-change')
		{
			setCurrentlyPlaying( [post.key, slide.slide_key]);
		}

		// this is called when user slides to the next post on mobile
		if (context=='post-change')
		{
			setCurrentlyPlaying( [post.key, null]);
		}

	}

	const toggleChatCallback = (post_key, open)=>
	{
		// console.log(post_key, open, open? post_key : null)
		setCurrentOpenChat(open ? post_key : null)
	}

	const chatMessageSubmitHandler = (user, message, parent, content_item)=>{


    console.log("chatMessageSubmitHandler", message, parent, content_item);

    return fetch(
      config.chatSubmitServiceUrl +
        "/" +
        content_item.key +
        "?session_id=" +
        user.getSessionID(),
      {
        method: "POST",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        redirect: "follow",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: message,
          parent: parent,

          // [todo]
          // pass slide/position info from Post

          
       //   position: currentlyPlaying.progress,
         // slide_key: content_item.active_slide_key || dict2arr(content_item.slides)[0].slide_key
        }),
      }
    )
      .then((response) => response.json())
      .then((response) =>
        checkIfSuccessful(
          response,
          // if the post was successful
          function (r) {



            updatePost(content_item.key, (post) => {
            	// content_item - obj to be updated
          		// post 				- current post in the feed
          		// r.post 			- Server response with and updated chat
            	let updatedPost = {...content_item, chat: r.post.chat}
              return { chat_open: true, ...updatedPost };
            });
            // msg_key holds the id of the added chat message
            // console.log('r', r);
            return r
          },
          function (r) {
            console.error("something was wrong..");
          }
        )
      );
    }

	const invitesHandler = (post, isAccepted)=>
	{

		console.log('The invite was', isAccepted ?'accepted':'declined', post);

		return fetch(
		  config.respondToInviteServiceUrl+'/'+ post.key+'/'+(isAccepted ? 'accept' : 'decline') + "?session_id=" + user.getSessionID(),
		  {
		    method: "POST",
		    mode: "cors", // no-cors, *cors, same-origin
		    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
		    redirect: "follow",
		    headers: {
		      Accept: "application/json",
		      "Content-Type": "application/json",
		    },
		    body : {}
		  }
		)
		.then((response) => response.json())
		.then((response) => {
		  console.log('invite response', response.post)

		  updatePost(post.key, (post)=>response.post )

		  return response

		})

	}


  function onUploadDone(post) 
	{

		// trying to replace an existing post with the updated
		let postExistsInFeed = updatePost(post.key, ()=> post)
		  
		  
		// if the post does not exist in the feed	
		if (!postExistsInFeed)
		{
			var newItems = [post, ...feed];
		  setFeed(newItems); 
		}

		if (post.slides.length > 0)
		{
		  
	    // playing the last item
	    // console.log('onUploadDone setting currently playing last item', )
	    setCurrentlyPlaying({
	      post_key : post.key, 
	      slide_key: post.slides[post.slides.length-1].slide_key,
	      progress:null
	    })
		  
		}
	}

	const deleteCollab = (content_item)=>
	{


			deletePost(content_item.key, user.getSessionID() ).then((r)=>{
				// console.log(r)
				// return;
				let newFeed = feed.filter((item) => item.key !== content_item.key)
				setFeed(newFeed)
				showFlashMessage("Collab deleted!");
				setContentItemToDelete(null)
			})
	}


	const onDeleteButtonClick = (content_item) =>
	{
			let user = new User();

			setContentItemToDelete(content_item)
		

	}





	useEffect(()=>{
		let user = new User();
		fetchFeedData(user)
			.then((items)=>{setFeed(items); return items})
			.then((items)=>{

				const params = new URLSearchParams(window.location.search);



				// if there are get params telling PA to goto a specific post
				//
				if (params.has('post_key'))
				{
					// let tmp = [params.get('post_key'), params.get('slide_key')];

					// areScrollEventsPaused.current = true;
					
					

						
					if (window.offsetWidth > 500)
					{
						let el = document.getElementById(params.get('post_key'));
						if (el)
						{
							console.log('we must to to ', el.offsetTop);
							window.scrollTo(0, el.offsetTop);	
						}

						
					}
					else
					{

						let index = items.map((item, i)=>item.key).indexOf(params.get('post_key'))
						
						if (index > 0)
						{
							console.log('setting default slide', index);
							setDefaultActivePost(index);
							setCurrentlyPlaying( [params.get('post_key'), null]  )
						}
						
						//
					}
						

				} 
				else
				{
					window.scroll(0, 2);// triggering autoplay.
				}
			})



		return ()=>{

			setFeed([])
		}

	},[])



	// const scrollToPost = (post)=>{
	// 	if (post !== null)
	// 	{
	// 		window.removeEventListener('scroll', scrollEvents);
	// 		console.log('scrollToPost', post, scrollY + post[1]);
	// 		window.scroll(0, scrollY + post[1])
	// 	}
	// }


	const scrollEvents = ()=>{


		if (areScrollEventsPaused === true) return;


		// setScrollY(window.scrollY )
		if (dict2arr(feed).length == 0) return;

		let scrollY = window.scrollY;


		let offsets = 
			dict2arr(feed).map((item)=> [item.key, document.getElementById(item.key).offsetTop - scrollY]);

		// find post that is most visible (closest to the top)
		let collabToPlay = offsets.sort((x, y)=> {
		if (Math.abs(x[1]) < Math.abs(y[1])) {
			return -1;
		} 
		if (Math.abs(x[1]) > Math.abs(y[1])) {
			return 1;
		}
			return 0;
		})[0]




		let isTheRightPostAlreadyPlaying = collabToPlay[0] === currentlyPlaying[0]
		// 
		if ( (Math.abs(collabToPlay[1]) < 300) && (!isTheRightPostAlreadyPlaying))
		// if (Math.abs(collabToPlay[1]) < 300)
		{
			setCurrentlyPlaying([collabToPlay[0], null])
		}
	}







	useEffect(()=>{
			// clearTimeout(autoScrollTimeout);
      window.addEventListener('scroll', scrollEvents)
      

      return ()=>{
      	window.removeEventListener('scroll', scrollEvents);
      }
  	})

	

	const showFlashMessage = (message, timeout, kind = "success")=>
	{
		setFlashMessage({message:message, timeout:timeout, kind:kind, show:true, rnd:new Date().getTime()})
	}
	
	
	const onUploadModalClose = ()=>{

		setUploadModalOpen({open:false, uploadTo:null, key: ( new Date().getTime()) });
		showFlashMessage("Collab created!")

	}


	const onDownloadFiles=(slides, content_item)=>	{

		updatePost(content_item.key, (post)=>{


			for (let i in slides)
			{
				// console.log('slide', slides[i])
				if (post.slides[slides[i]])
				{
					post.slides[slides[i]].is_downloaded = true;	
				}
				
			}
			return post

		})

	}

	const onCollabShared = (collab)=>{
		setFeed([collab, ...feed])
	}






	var user = new User();
	return (
		<>
			<TopNav 
		        user={user}
		        homeUrl="/home"
		        gridUrl="/grid"

		    />
		  
		  <FlashMessage {...flashMessage} />

			<FeedComponent
				items={feed}
				controlsCallback={controlsCallback}
				currentlyActiveItem={currentlyPlaying}
				defaultActiveSlide={defaultActivePost}
				currentOpenChat={currentOpenChat}
				toggleChatCallback={toggleChatCallback}
				chatMessageSubmitHandler={(message, parent, content_item)=>chatMessageSubmitHandler(user, message, parent, content_item)}
				contentActionsClickHandler={contentActionsClickHandler}
				invitesHandler={invitesHandler}
			/>


			<UploadModal
		        key={uploadModalOpen.key}
		        open={uploadModalOpen.open}
		        handleClose={onUploadModalClose}
		        onCollabCreated={onUploadDone}
		        uploadTo={uploadModalOpen.uploadTo}
		        welcomeH1="Drop your files here, or"
		        modalH3={ uploadModalOpen.uploadTo===null ? "New collab" : "Add files to a collab"}
		        flashMessageCallback={showFlashMessage}
		        showDescriptionAndInvites={uploadModalOpen.uploadTo===null} // do not show this for add files


		    />

		  	<AddPeopleModal
		  		addTo={addPeopleModalContent}
		  		isOpen={addPeopleModalContent!== null}
		  		handleClose={(e)=>setAddPeopleModalContent(null)}
		  		flashMessageCallback={showFlashMessage}
		  	/>

		    <DownloadModal
		        isOpen={downloadModalContent !== null}
		        content_item={downloadModalContent}
		        handleClose={(e) => setDownloadModalContent(null)}
		        onDownload={onDownloadFiles}
		        flashMessageCallback={showFlashMessage}
		    />

		    <ShareToModal
		        isOpen={shareToModalContent !== null}
		        content_item={shareToModalContent}
		        handleClose={(e) => setShareToModalContent(null)}
		        key={shareToModalContent ? shareToModalContent.key : ''}
		        onCollabShared={(collab)=>onCollabShared(collab)}
		        flashMessageCallback={showFlashMessage}
		        // onDownload={onDownloadFiles}
		    />

		    <DeleteConfirmation 
		     	show={contentItemToDelete != null} 
		     	content_item={contentItemToDelete}
		     	cancelCallback={()=>setContentItemToDelete(null)}
		     	confirmCallback={()=>deleteCollab(contentItemToDelete)}
		    />


			<BottomNav 
				onAddClick={() => setUploadModalOpen({open:true, uploadTo:null, key: ( new Date().getTime() )})} 
				homeUrl="/home"
				dmUrl="/dm"
				key="const"
			/>
		</>
	)
}