import React, {useState, useRef, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';


import {Spinner} from "../../components/spinner.component";


import './scss/upload-modal-alt.scss'
import './scss/upload-splash.scss'


export const UploadSplash = ({onFilesDropped, welcomeH1, isLoading=false}) =>{


	var fileSelect= useRef(null);
	// const [files, setFiles] = useState([]);



	const handleDrop = (event) => {
		if (isLoading) return;
	    event.preventDefault();
	    const droppedFiles = event.dataTransfer.files;
	    if (droppedFiles.length > 0) {
	      // const newFiles = Array.from(droppedFiles);
	      // setFiles((prevFiles) => [...prevFiles, ...newFiles]);
	      onFilesDropped(Array.from(droppedFiles));
	    }

	};


	const openFileDialog = ()=>
	{
		if (isLoading) return;
		// the id comes from parent component
		document.getElementById('selectFilesElement').click()
	}


  	

	return (
		<>
			<Modal.Body 
				// className="drag-n-drop"
				onDrop={handleDrop}
	        	onDragOver={(event) => event.preventDefault()}
			>
					

					{isLoading
					 ? <Spinner/>
					 : (<>
						 	<h1>{welcomeH1}</h1>
							<button 
								className="button button-primary"
								onClick={openFileDialog}
							>
								Select from your device
							</button>
						</>)
					 
					}

					
			</Modal.Body>

		</>
	);
}
