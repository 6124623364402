
import React, {useState, useEffect, useRef} from 'react';

	// <Animation
	// 	path="http://localhost:3000/static/animation3/frame-"
	// 	imagesCount={78}
	// 	isPlaying={isPlaying}
	// 	onAnimationEnd={  ()=>{
	// 		setIsPlaying(false)
	// 		return false;
	// 	}}

	// />



export const Animation = ({path, imagesCount, skipFrames=1,  canvasWidth, canvasHeight, onAnimationEnd, isPlaying})=>{

	
	var canvas= useRef(null)
	var [isReady, setReady] = useState(false);
	var [images, setImages] = useState([]);
	var frameRate = 30 * skipFrames;



	const preloadImages = (urls) => {
	  const promises = urls.map((url) => {
	    return new Promise((resolve, reject) => {
	      const image = new Image();

	      image.src = url;

	      image.onload = () => resolve(image);
	      image.onerror = () => reject(`Image failed to load: ${url}`);
	    });
	  });

	  return Promise.all(promises);
	}

	const genFilenames=(path, count)=> {
		let names = []
		for (var i=1;i<count;i+=skipFrames) // 65
		{
			let nr = i+''
			while (nr.length < 6){ nr = '0'+nr}
			// let filename = 'http://localhost:3000/static/animation2/frame-'+nr+'.png'

			let filename = path+nr+'.png';	
			names.push(filename)	
		}
		// console.log(names)
		return names;
	}	


	const runAnimation=(canvas, duration, onAnimationFrame)=>
	{
		
		var timeFraction = 0;
		const ctx = canvas.getContext("2d");
		var t0 = performance.now()

		const onFrame = (time)=>{
			
			ctx.clearRect(0, 0, canvas.width, canvas.height)
			let timeElapsed = time>= t0 ? time - t0 : 0 ;

			if (timeElapsed<=duration)
			{
				onAnimationFrame(ctx, timeElapsed)
				requestAnimationFrame(onFrame)
			}
			else
			{
				// console.log('reached the end')
				t0 = performance.now()
				if (onAnimationEnd())
				{
					timeElapsed = time>= t0 ? time - t0 : 0 ;
					onAnimationFrame(ctx, timeElapsed)
					requestAnimationFrame(onFrame)
				}
				else
				{
					// console.log('not looping')
				}
			}
		}
		requestAnimationFrame(onFrame)
	}


	// const {getFrames, frames} = useContext(AnimationContext);
		


	useEffect(()=>{
		if (images.length == 0)
		{


			let frames = genFilenames(path, imagesCount)
			let t0 = new Date().getTime()
			preloadImages(frames).then((images)=>{
				// console.log('ready to play', images.length)
				setImages(images)

				if (images.length == 0)
				{
					// console.log('no frames to play')
				}
				else
				{
					// setReadyToPlay(true)
					var t1 = new Date().getTime()
					// console.log('Frames are ready in ', (t1-t0)/1000, ' sec ');
					setReady(true);
				}
			})
		}

	}, [path, skipFrames, imagesCount])


	useEffect(()=>{
		// console.log('isPlaying', isPlaying)
		if (!isPlaying)
		{
			return 
		}

		if (!isReady)
		{
			console.log('Frames are not loaded')
			onAnimationEnd()
			return 
		}
		if (images.length == 0)
		{
			console.log('no frames to play')
			onAnimationEnd()
			return
		}




		// var frames = getFrames('whatever')


		var frameIndex = 0;
		let canvasWidth = canvas.current.parentNode.offsetWidth;
		let canvasHeight = canvas.current.parentNode.offsetHeight;
		const ctx = canvas.current.getContext("2d");

		canvas.current.width = canvasWidth ;
		canvas.current.height = canvasHeight;
		

		// console.log('frames', images)
		


		// the rate is 30fps...
		// so a new slide every 33 ms
		// console.log('playing now..', )
	
		
		

		runAnimation(canvas.current ,frameRate * images.length, (ctx, timeElapsed)=>{
			let frameIndex = Math.floor(timeElapsed /frameRate)
			if (frameIndex < images.length)
			{
				// setFrameCounter(frameIndex)
				ctx.drawImage(images[frameIndex], 0,0, canvasWidth, canvasHeight)
				// ctx.beginPath();
				// ctx.moveTo(0, 0);
				// ctx.lineTo(100, 100);
				// ctx.stroke();
			}
			else
			{
				console.log('not enough frames', frameIndex, images.length)
			}
		})


	},[isPlaying])
// 





	return (

		<>
		<canvas
			ref={canvas}
			className="animation-canvas"
			style={{
				// border:'1px solid red',
				// display:'block',
				position:'absolute',
				// width:'100%',
				// height:'100%'
				inset:0
			}}
		/>
		</>
		)
	



}




